import React from "react";
import { Tooltip } from "antd";
import Chat from "components/Conversation/Chat";
import styles from "./phoneframe.module.scss";

const PhoneFrame = props => {
  const {
    headerName = "GUNI SMS",
    messageList,
    showMessageBox = false,
    currentMessageContent,
    handleInputChange,
    handleSend,
    containerStyle,
    sender,
    isMms = false,
    phoneFrameImageUrl,
    smallFrame = false
  } = props;

  return (
    <>
      <div className={smallFrame ? styles.containerSmall : styles.container} style={containerStyle}>
        <div className={styles.headerName}>
          <h5 className="fw-900 d-flex justify-content-center">
            {headerName}
            {sender && (
              <Tooltip title={`Sender ID: ${sender}`} placement="top">
                <div>
                  <i className="fas fa-info-circle text-info-color ms-2" />
                </div>
              </Tooltip>
            )}
          </h5>
        </div>
        <Chat
          messageList={messageList}
          currentMessageContent={currentMessageContent}
          showMessageBox={showMessageBox}
          handleInputChange={handleInputChange}
          handleSend={handleSend}
          chatBoxClass={styles.chatBox}
          isMms={isMms}
          phoneFrameImageUrl={phoneFrameImageUrl}
        />
      </div>
    </>
  );
};

export default PhoneFrame;
