import React from "react";
import styles from "./formPicker.module.scss";
import { DatePicker, Form } from "antd";

const { RangePicker } = DatePicker;

const AppDatePicker = props => {
  const {
    containerClassname = "",
    pickerClassname = "",
    containerProps = {},
    pickerProps = {},
    errors,
    touched,
    value,
    onChange,
    showTime,
    size = "large",
    name,
    placeholder = "Select Time",
    rangeSelector = false,
    secondary = false
  } = props;
  let finalClassNames = styles.formPickerPrimary;
  if (secondary) {
    finalClassNames = styles.formPickerSecondary;
  }
  return (
    <Form.Item className={`${finalClassNames} ${containerClassname}`} validateStatus={errors && touched ? "error" : ""} help={errors && touched ? errors : ""} {...containerProps}>
      {rangeSelector ? (
        <RangePicker showTime={showTime} placeholder={placeholder} onChange={onChange} name={name} size={size} value={value} className={pickerClassname} {...pickerProps} />
      ) : (
        <DatePicker showTime={showTime} placeholder={placeholder} onChange={onChange} name={name} value={value} size={size} className={pickerClassname} {...pickerProps} />
      )}
    </Form.Item>
  );
};

export default AppDatePicker;
