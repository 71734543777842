import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import { totalSaving } from "constant/buyCreditsConstants";
import { inject, observer } from "mobx-react";

const OrderSummary = ({ bonus, cost = 0, price = 0, total = 0, quantity = 0, isFirstBuy = false, modalStore }) => {
  return (
    <Row className="rounded-xl border p-md-4 p-2 h-100" type="flex" align="start">
      <Col span={24}>
        <Typography.Title level={4} className="text-uppercase gotham-bold">
          Order Summary
        </Typography.Title>
      </Col>
      <Divider />
      <Col span={16}>
        <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
          {" "}
          Total No of {modalStore.isMms ? "MMS" : "SMS"}
        </Typography.Title>
      </Col>
      <Col span={8}>
        <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
          {quantity}{" "}
          {bonus && (
            <span className="gotham-bold">
              + {bonus}{" "}
              <span className="text-danger" style={{ fontSize: "small" }}>
                (Bonus)
              </span>
            </span>
          )}
        </Typography.Title>
      </Col>
      <Col span={16}>
        <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
          {" "}
          Cost per {modalStore.isMms ? "MMS" : "SMS"}
        </Typography.Title>
      </Col>
      <Col span={8}>
        <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
          {cost} cents
        </Typography.Title>
      </Col>
      <Col span={16}>
        <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
          Subtotal{" "}
        </Typography.Title>
      </Col>
      <Col span={8}>
        <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
          ${price}
        </Typography.Title>
      </Col>
      {isFirstBuy && (
        <>
          <Col span={16}>
            <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
              Saving on First Buy{" "}
            </Typography.Title>
          </Col>
          <Col span={8}>
            <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
              ${totalSaving(quantity, price, modalStore.isMms) < 0 ? 0 : totalSaving(quantity, price, modalStore.isMms)}
            </Typography.Title>
          </Col>
        </>
      )}
      <Col span={16}>
        <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
          GST <Typography.Text className="fs-6">(10%)</Typography.Text>{" "}
        </Typography.Title>
      </Col>
      <Col span={8}>
        <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
          ${((price / 100) * 10).toFixed(2)}
        </Typography.Title>
      </Col>
      <Divider />
      <Col span={16}>
        <Typography.Title level={4} className="fs-5 text-uppercase gotham-bold">
          Total{" "}
        </Typography.Title>
      </Col>
      <Col span={8}>
        <Typography.Title level={4} className="text-uppercase gotham-bold text-end fs-5">
          ${total?.toFixed(2)}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(OrderSummary));
