import { runInAction, decorate, observable } from "mobx";
import {
  addGatewayMMS,
  getAllGatewayMMS,
  addGatewayMMSBulk,
  getAllGatewayMMSBulk,
  renameBulk,
  getExportBulkLogList,
  getBulkDetailsById,
  getBulkMessages,
  getBulkShortUrlAnalytics,
  addGatewayMMSBulkByList
} from "service/gatewayMmsService";
import { getScheduledSMSBulkList, getAllSavedBulks, getBulkListForApprovalOfUser, getCampaignDetails, rescheduleMessage, cancelScheduleMessage } from "service/gatewayService/index";

class ObservableGatewayMmsStore {
  gatewayMMSList = [];
  messagesList = [];
  bulkList = [];
  clicksList = [];
  campaignMmsReport = undefined;
  addGatewayMMSResponse = undefined;
  addGatewayMMSBulkResponse = undefined;
  showNewCreateMessageAlert = false;
  total = 0;
  pageSize = 10;
  totalPages = 0;
  current = 1;
  click = false;
  search = undefined;
  sortField = "createdAt";
  sortOrder = "descend";
  campaign = undefined;
  renameBulkResponse = undefined;
  exportBulkLogList = [];
  selectedBulk = undefined;
  response = undefined;

  // for mms editor
  mmsMedia = undefined;
  handleSubmit = undefined;

  setMmsMedia = file => {
    runInAction(() => {
      this.mmsMedia = file;
    });
  };

  setSubmit = callback => {
    runInAction(() => {
      this.handleSubmit = callback;
    });
  };

  setCampaignData = campaign => {
    runInAction(() => {
      campaign ? (this.campaign = campaign) : (this.campaign = undefined);
    });
  };

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = size => {
    runInAction(() => {
      this.pageSize = size;
      this.current = 1;
    });
  };

  setClick = click => {
    runInAction(() => {
      this.click = click;
      this.current = 1;
    });
  };

  selectBulk = bulkData => {
    this.selectedBulk = bulkData ? JSON.parse(JSON.stringify(bulkData)) : undefined;
  };

  getAllGatewayMMS = async args => {
    const params = {
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    const getAllGatewayMmsResponse = await getAllGatewayMMS(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = getAllGatewayMmsResponse;
        this.gatewayMMSList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
      });
    } catch (err) {
      runInAction(() => {
        this.addGatewayMMSResponse = err;
      });
    }
    return this.gatewayMMSList;
  };

  /**
   * add new MMS
   * @param data
   * @returns {Promise<*>}
   */

  addGatewayMMS = async data => {
    const addGatewayMMSResponse = await addGatewayMMS(data);
    try {
      runInAction(() => {
        this.addGatewayMMSResponse = addGatewayMMSResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGatewayMMSResponse = error;
      });
    }
    return this.addGatewayMMSResponse;
  };

  updateMessageStatus = async statusObj => {
    try {
      runInAction(() => {
        const findGatewayMessageIndex = this.gatewayMMSList.findIndex(x => x._id === statusObj._id);
        if (findGatewayMessageIndex > -1) {
          const tempMessageObj = {
            ...this.gatewayMMSList[findGatewayMessageIndex]
          };
          tempMessageObj.status = statusObj.status;
          this.gatewayMMSList.slice(findGatewayMessageIndex, 1);
          const tempGatewayMMSList = [...this.gatewayMMSList];
          tempGatewayMMSList[findGatewayMessageIndex].status = statusObj.status;
          this.gatewayMMSList = tempGatewayMMSList;
        } else if (this.current === 1 && this.search === undefined) {
          //for received mms,put then in first
          statusObj.createdAt = new Date(statusObj.createdAt).toLocaleString();
          this.gatewayMMSList = [statusObj, ...this.gatewayMMSList];
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteMessageStatus = async data => {
    try {
      runInAction(() => {
        const filteredMessageList = this.gatewayMMSList.filter(item => item._id !== data._id);
        this.gatewayMMSList = filteredMessageList;
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * add MMS Bulk
   * @param data
   * @returns {Promise<*>}
   */

  addGatewayMMSBulk = async data => {
    const addGatewayMMSBulkResponse = await addGatewayMMSBulk(data);
    try {
      runInAction(() => {
        this.addGatewayMMSBulkResponse = addGatewayMMSBulkResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGatewayMMSBulkResponse = error;
      });
    }
    return this.addGatewayMMSBulkResponse;
  };

  addGatewayMMSBulkByList = async data => {
    const addGatewayMMSBulkResponse = await addGatewayMMSBulkByList(data);
    try {
      runInAction(() => {
        this.addGatewayMMSBulkResponse = addGatewayMMSBulkResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGatewayMMSBulkResponse = error;
      });
    }
    return this.addGatewayMMSBulkResponse;
  };

  /**
   * get bulk MMS
   * @param params
   */

  getAllGatewayMMSBulk = async args => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    const getAllGatewayMmsBulkResponse = await getAllGatewayMMSBulk(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = getAllGatewayMmsBulkResponse;
        this.bulkList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
      });
    } catch (err) {
      runInAction(() => {
        this.addGatewayMMSBulkResponse = err;
      });
    }
    return this.bulkList;
  };

  getAllSavedBulks = async args => {
    const params = {
      page: args ? this.current + args : this.current,
      camp_type: "mms",
      limit: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    const bulkListResponse = await getAllSavedBulks(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = bulkListResponse;
        this.bulkList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.gatewayMMSList;
  };

  renameBulk = async (id, data) => {
    const renameBulkResponse = await renameBulk(id, data);
    try {
      runInAction(() => {
        this.renameBulkResponse = renameBulkResponse;
      });
      this.getAllGatewayMMSBulk();
    } catch (error) {
      runInAction(() => {
        this.renameBulkResponse = error;
      });
    }
    return this.renameBulkResponse;
  };

  // Export
  getExportLogList = async params => {
    const exportBulkLogListResponse = await getExportBulkLogList(params);
    try {
      runInAction(() => {
        this.exportBulkLogList = exportBulkLogListResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    } finally {
      return this.exportBulkLogList;
    }
  };

  getBulkMessages = async (bulkId, args) => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };
    const getBulkMessagesResponse = await getBulkMessages(bulkId, params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBulkMessagesResponse.data;
        this.messagesList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.messagesList;
  };

  getBulkShortUrlAnalytics = async (bulkId, args) => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };

    params.clickstatus = this.click;
    const getBulkShortUrlAnalyticsResponse = await getBulkShortUrlAnalytics(bulkId, params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBulkShortUrlAnalyticsResponse.data;
        this.clicksList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.clicksList;
  };

  getBulkListForApprovalOfUser = async isAdd => {
    const params = {
      page: isAdd ? this.current + isAdd : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      camp_type: "mms"
    };
    if (this.search) params.search = this.search;
    const getBulkApprovalListResponse = await getBulkListForApprovalOfUser(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = getBulkApprovalListResponse;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || getBulkApprovalListResponse), "_id") : _.uniq(items || getBulkApprovalListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.bulkList;
  };

  getScheduledMMSBulkList = async isAdd => {
    const params = {
      page: isAdd ? this.current + isAdd : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      camp_type: "mms"
    };
    if (this.search) params.search = this.search;
    const scheduledMMSBulkList = await getScheduledSMSBulkList(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = scheduledMMSBulkList;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || scheduledMMSBulkList), "_id") : _.uniq(items || scheduledMMSBulkList, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.bulkList = error;
      });
    }
    return this.bulkList;
  };

  getBulkDetailsById = async id => {
    const bulkDetailResponse = await getBulkDetailsById(id);
    try {
      runInAction(() => {
        this.campaignMmsReport = bulkDetailResponse[0];
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.campaignMmsReport;
  };

  // socket add message in history
  newMessageEvent = messageObj => {
    try {
      if (this.gatewayMMSList && this.current === 1 && this.search === undefined) {
        this.addMessageToGatewayList(messageObj);
      } else {
        this.setshowNewCreateMessageAlert();
      }
    } catch (error) {
      console.log(error);
    }
  };

  addMessageToGatewayList = async messageObj => {
    const insertMessageObj = {
      ...messageObj,
      createdAt: new Date(messageObj.createdAt).toLocaleString()
    };
    try {
      runInAction(() => {
        const tempMessageList = [...this.gatewayMMSList];
        if (tempMessageList.length >= 50) {
          tempMessageList.pop();
        }
        this.gatewayMMSList = [insertMessageObj].concat(tempMessageList);
      });
    } catch (error) {
      console.log(error);
    }
    return this.gatewayMMSList;
  };

  setshowNewCreateMessageAlert = () => {
    runInAction(() => {
      this.showNewCreateMessageAlert = !this.showNewCreateMessageAlert;
    });
  };

  getCampaignDetails = async id => {
    const response = await getCampaignDetails(id);
    try {
      runInAction(() => {
        this.selectedBulk = response.data;
      });
    } catch (error) {
      runInAction(() => {
        this.selectedBulk = error;
      });
    }
    return this.selectedBulk;
  };

  rescheduleMessage = async (id, data) => {
    const response = await rescheduleMessage(id, data);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.gatewayMMSList = this.gatewayMMSList.map(item => {
            if (item._id === id) {
              item.scheduleAt = data.scheduleAt;
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  cancelScheduleMessage = async id => {
    const response = await cancelScheduleMessage(id);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.gatewayMMSList = this.gatewayMMSList.map(item => {
            if (item._id === id) {
              item.status = "cancel_schedule";
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(ObservableGatewayMmsStore, {
  gatewayMMSList: observable,
  showNewCreateMessageAlert: observable,
  bulkList: observable,
  messagesList: observable,
  clicksList: observable,
  campaignMmsReport: observable,
  addGatewayMMSResponse: observable,
  addGatewayMMSBulkResponse: observable,
  addGatewayMMS: observable,
  addGatewayMMSBulk: observable,
  pageSize: observable,
  current: observable,
  totalPages: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  campaign: observable,
  renameBulkResponse: observable,
  exportBulkLogList: observable,
  click: observable,
  selectedBulk: observable,
  mmsMedia: observable,
  mmsMediaResult: observable,
  response: observable
});
export default new ObservableGatewayMmsStore();
