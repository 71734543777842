import { runInAction, decorate, observable, toJS } from "mobx";
import { createOrUpdate, getList, deleteById } from "service/contactFormService";
import * as _ from "lodash";
import { BASE_URL } from "constant/apiService";
const intialValues = {
  activeFormData: {
    name: undefined,
    group: undefined,
    whitelistedDomains: []
  },

  formFields: [
    { label: "name", isCustom: false },
    { label: "mobile", isCustom: false }
  ],
  automationSetting: {
    emailToTrigger: "",
    senderId: "#SharedNum#",
    delay: {
      days: 0,
      hours: 0,
      minutes: 0
    },
    instantMessage: "Hi {{name}},Thanks for subscribing.",
    redirectUrl: undefined
  }
};
class ObservableContactFormStore {
  step = 0;
  contactFormsList = [];
  activeFormData = intialValues.activeFormData;
  formFields = intialValues.formFields;
  automationSetting = intialValues.automationSetting;

  selectedContactForm = undefined;

  updateStep = number => {
    runInAction(() => {
      //number can be negative
      this.step = this.step + number;
    });
  };

  addField = field => {
    runInAction(() => {
      field.isCustom = true;
      const temp = toJS(this.formFields);
      temp.push(field);
      this.formFields = temp;
    });
  };
  deleteField = index => {
    runInAction(() => {
      const temp = toJS(this.formFields);
      _.pullAt(temp, index);
      this.formFields = temp;
    });
  };

  updateField = ({ data, index }) => {
    runInAction(() => {
      const temp = [...this.formFields];
      temp[index] = data;
      this.formFields = temp;
    });
  };

  setForm = ({ activeFormData, formFields, automationSetting }) => {
    runInAction(() => {
      this.activeFormData = activeFormData;
      this.formFields = formFields;
      this.automationSetting = automationSetting;
    });
  };

  resetForm = () => {
    console.log("reseting");
    runInAction(() => {
      this.step = 0;
      this.activeFormData = intialValues.activeFormData;
      this.formFields = intialValues.formFields;
      this.automationSetting = intialValues.automationSetting;
      this.selectedContactForm = undefined;
    });
  };

  createOrUpdate = async () => {
    const data = {
      ...this.activeFormData,
      formFields: this.formFields,
      automationSetting: this.automationSetting
    };
    if (this.selectedContactForm) {
      data._id = this.selectedContactForm._id;
    }
    const response = await createOrUpdate(data);

    runInAction(() => {
      this.selectedContactForm = response.data;
    });
    return response.data;
  };

  deleteById = async ({ _id }) => {
    const response = await deleteById({ _id });

    runInAction(() => {
      this.selectedContactForm = response.data;
    });
    return response.data;
  };

  getList = async () => {
    const response = await getList();
    console.log({ response });
    runInAction(() => {
      this.contactFormsList = response.data;
    });
    return response.data;
  };

  generateFormCode = ({ contactForm }) => {
    let code = `<form action="${BASE_URL}/webhook/contact-form-report" method="post"> 
    <input type="hidden" name="id" value="${contactForm._id}"/>
    <input type="hidden" name="country_code" value="aud"/>
    `;

    const { formFields } = contactForm;

    formFields.forEach(({ label }) => {
      const temp = `<label for="${label}">${label}</label>
      <input type="text" name="${label}" id="${label}" required="true"/>
      <br/>`;

      code += temp;
    });

    code += `
    <input type="checkbox" name="opt_in" id="opt_in"checked/>
    <label for="opt_in"> Opt in</label>
    <button type="submit">Submit</button>
    </form>`;
    console.log(code);
    return code;
  };
}

decorate(ObservableContactFormStore, {
  step: observable,
  contactFormsList: observable,
  activeFormData: observable,
  formFields: observable,
  automationSetting: observable,
  selectedContactForm: observable
});

export default new ObservableContactFormStore();
