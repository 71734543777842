import { get, post, put, deleteRequest } from "utils/api";

/**
 * Admin get Template By user Id
 * @param userId
 * @returns {Promise<any>}
 */
export const getTemplateUser = async userId => {
  const TemplateListResponse = await get(`/admin/template/${userId}`);
  return TemplateListResponse.data;
};

/**
 * Call get all template api
 * @returns {Promise<any>}
 */
export const getAllTemplates = async params => {
  const TemplateListResponse = await get("/mobile/template", params);
  return TemplateListResponse.data.data;
};

/**
 * Add new template api
 * @param templateData
 * @returns {Promise<any>}
 */
export const addTemplate = async (templateData = {}) => {
  const addResponse = await post(`/mobile/template`, templateData);
  return addResponse.data;
};

/**
 * Add new mms template api
 * @param templateData
 * @returns {Promise<any>}
 */
export const addMmsTemplate = async (templateData = {}) => {
  const addResponse = await post(`/template/mms`, templateData, true, {
    "Content-Type": `multipart/form-data`
  });
  return addResponse.data;
};

/**
 * Update existing template Api
 * @param templateData
 * @param templateId
 * @returns {Promise<any>}
 */
export const updateTemplate = async (templateData = {}, templateId) => {
  const updateResponse = await put(`/mobile/template/${templateId}`, templateData);
  return updateResponse.data;
};

/**
 * Update existing mms template Api
 * @param templateData
 * @param templateId
 * @returns {Promise<any>}
 */
export const updateMmsTemplate = async (templateData = {}, templateId) => {
  const updateResponse = await put(`/template/mms/${templateId}`, templateData, true, {
    "Content-Type": "multipart/form-data"
  });
  return updateResponse.data;
};

/**
 * Delete template
 * @param TemplateId
 * @returns {Promise<any>}
 */
export const deleteTemplate = async templateId => {
  const deleteResponse = await deleteRequest(`/mobile/template/${templateId}`);
  return deleteResponse.data;
};

/**
 * Get Template By Id
 * @param templateId
 * @returns {Promise<any>}
 */

export const getTemplateById = async templateId => {
  const TemplateResponse = await get(`/mobile/template/${templateId}?visibility=user`);
  return TemplateResponse.data;
};

/**
 * Create Global Template
 * @param templateData
 * @returns {Promise<any>}
 */

export const createGlobalTemplate = async templateData => {
  const TemplateResponse = await post(`/admin/create-global-template`, templateData);
  return TemplateResponse.data;
};

/**
 * mark as global template
 * @param templateId
 * @returns {Promise<any>}
 */

export const markAsGlobalTemplate = async templateId => {
  const TemplateResponse = await put(`/admin/mark-as-global-template/${templateId}`);
  return TemplateResponse.data;
};
