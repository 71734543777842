import { Tooltip } from "antd";
import React from "react";

const IconsMenu = props => {
  const { options = [], onClick } = props;
  return (
    <div className="d-flex align-items-center gap-4 justify-content-end">
      {options.map((option, i) => (
        <Tooltip key={i} title={option.label} placement="top">
          <i className={`fs-5 fas fa-${option.icon} text-info-hover cursor-pointer text-muted ${option?.className}`} type="button" onClick={() => onClick(option.value)} />
        </Tooltip>
      ))}
    </div>
  );
};

export default IconsMenu;
