import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Icon } from "antd";
import AlertModal from "components/Modals/AlertModal";
import icon1 from "assets/images/mobile-view/quick-sms.png";
import icon2 from "assets/images/mobile-view/sms-campaign.png";
import { sharedNumber } from "constant/constant";

function FloatButton(props) {
  const { modalStore, messageStore, gatewayBulkStore, authStore } = props;
  const { user, allSenders } = authStore;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [draftAlert, setDraftAlert] = useState(false);

  const showModal = () => {
    setVisible(!visible);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCreateCampaign = (mms = false) => {
    if (localStorage.getItem("campaign")) {
      setDraftAlert(true);
    } else {
      localStorage.setItem("step", 0);
      localStorage.removeItem("campaign");
      gatewayBulkStore.setCampaignData(null);
      messageStore.setCurrentMessage("");
      messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
      messageStore.setCurrentImage(null);
      history.push(mms ? "/mms/campaign/create" : "/campaign/create");
    }
  };

  return (
    <div>
      <div className="rightBottombtn">
        <i className={`${visible ? "active" : ""} fas fa-plus text-white rounded-circle`} onClick={showModal} />
        <Modal visible={visible} footer={null} closable={false} onOk={handleOk} onCancel={handleCancel} className="rightbottomsmsquick" bodyStyle={{ padding: 0 }}>
          <div>
            <p>
              <Link
                to="/quick-message"
                onClick={() => {
                  modalStore.setIsMms(false);
                  modalStore.selectedTemplate = undefined;
                  modalStore.longUrl = undefined;
                  modalStore.setQuickSMSData(null);
                }}
              >
                <i className="fas fa-send text-dark fs-5 bg-light rounded-circle p-2 me-2" />
                <span className="bg-light">Quick Message</span>
              </Link>
            </p>
            {/* <p>
              <Link
                to="/quick-sms"
                onClick={() => {
                  modalStore.setIsMms(true);
                  modalStore.selectedTemplate = undefined;
                  modalStore.longUrl = undefined;
                  modalStore.setQuickSMSData(null);
                }}
              >
                <img src={icon1} alt="social-icon" className="img-fluid me-2" />
                <span>Quick Mms</span>
              </Link>
            </p> */}
            <p>
              <div onClick={handleCreateCampaign}>
                <i className="fas fa-comments text-dark fs-5 bg-light rounded-circle p-2 me-2" />
                <span className="bg-light">Campaign</span>
              </div>
            </p>
            {/* <p>
              <div onClick={() => handleCreateCampaign(true)}>
                <img src={icon2} alt="social-icon" className="img-fluid me-2" />
                <span>Mms Campaign</span>
              </div>
            </p> */}
          </div>
        </Modal>
      </div>
      <AlertModal
        visible={draftAlert}
        title="Use draft campaign"
        desciption="You have a pending campaign stored. Wanna use it?"
        primaryBtnProps={{
          label: "Use Draft",
          onClick: () => {
            history.push("/campaign/create");
            setDraftAlert(false);
          }
        }}
        secondaryBtn
        secondaryBtnProps={{
          label: "Create New",
          onClick: () => {
            setDraftAlert(false);
            localStorage.removeItem("campaign");
            gatewayBulkStore.setCampaignData(null);
            localStorage.setItem("step", 0);
            history.push("/campaign/create");
            messageStore.setCurrentMessage("");
            messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
            messageStore.setCurrentImage(null);
          }
        }}
        closable
        onCancel={() => setDraftAlert(false)}
      />
    </div>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  authStore: stores.store.authStore
}))(observer(FloatButton));
