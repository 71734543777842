import AppButton from "components/AppButton/AppButton";
import InvoiceModal from "components/RetailChat/Modals/InvoiceModal";
import { inject, observer } from "mobx-react";
import React from "react";

const RetailChatInvoice = ({ modalStore, size = "small", btnProps = {} }) => {
  function handleClick() {
    modalStore.toggleModal("retailChatInvoice", true);
  }

  return (
    <>
      <AppButton className="border-0 bg-light" prefixIcon={<span className="text-decoration-underline text-info-color">Create Invoice</span>} onClick={handleClick} size={size} {...btnProps} />
      <InvoiceModal />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(RetailChatInvoice));
