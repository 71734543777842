import React, { useState } from "react";
import AppButton from "components/AppButton/AppButton";
import clsx from "clsx";
import { Tag } from "antd";

const AddMergeLabel = props => {
  const { onClick, labels, btnProps, size } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <AppButton
        className="border-0 bg-light"
        withoutBg
        prefixIcon={<span className="text-decoration-underline text-info-color">Personalize</span>}
        size={size}
        {...btnProps}
        onClick={() => {
          setVisible(!visible);
        }}
      />
      <div className={clsx("w-100 merge-label", { show: visible })}>
        <div className="d-flex flex-wrap gap-2 mx-2">
          {labels.fields.map(label => (
            <Tag className="rounded-pill pe-auto" key={label} onClick={onClick} type="button">
              {label}
            </Tag>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddMergeLabel;
