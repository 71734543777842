import React, { useState, useEffect } from "react";
import { Card, Col, Collapse, Progress, Row, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import { Form, Formik } from "formik";
import SenderIDMenu from "components/GatewayMessage/SenderID/SenderID";
import { guniHelpUrl, sharedNumber } from "constant/constant";
import * as Yup from "yup";
import EmailOtpModal from "components/TrialUser/Modals/EmailOtpModal";

const { Panel } = Collapse;

const urls = {
  group: guniHelpUrl + "/create",
  senderid: guniHelpUrl
};

const DashboardChecklist = props => {
  const { loading, checklist, modalStore, authStore } = props;
  const { user, updateProfileConfig } = authStore;
  const history = useHistory();
  const [activeKeys, setActiveKeys] = useState([]);
  const [collapseData, setCollapseData] = useState([
    {
      key: "0",
      id: "verified",
      title: "Account Verified",
      pending: "Your account is not verified. Please verify your account to start sending messages.",
      success: "Your account has been verified. You can now start sending messages.",
      status: authStore?.user?.role === "trial" ? false : true
    },
    {
      key: "1",
      id: "sender",
      title: "Select Default Sender ID",
      description: (
        <>
          Sender ID is the number/name your customers will see as a sender when they receive your Campaign message.{" "}
          <a href={urls.senderid} target="_blank" className="text-info-color">
            know more
          </a>
        </>
      ),
      status: false
    },
    {
      key: "2",
      id: "template",
      title: "Create a Message Template",
      description: "You can create message templates so that you can readily re-use them across various campaigns.",
      status: false
    },
    {
      key: "3",
      id: "group",
      title: "Create Audience Group",
      pending: (
        <>
          Start uploading your audience data and save them as groups to use them for targeted campaigns.{" "}
          <a href={urls.group} target="_blank" className="text-info-color">
            know more
          </a>
        </>
      ),
      success: "You have created a group. You can now start running campaigns and configure them.",
      status: false
    },
    {
      key: "5",
      id: "campaign",
      title: "Create a Campaign",
      pending: "When you are ready with your database, you can now start running campaigns and configure them.",
      success: "You have successfully run campaign. You can now check the status of your campaign and export report in excel sheet.",
      status: false
    }
  ]);
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    let data = [...collapseData];
    if (checklist) {
      data = collapseData.map(item => {
        const data = checklist.find(c => c.id == item.id);
        if (data) {
          item.status = data.status;
        }
        return item;
      });
    }
    setCollapseData(data);
    // let keys = data.filter(item => item.status == false).map(item => item.key);
    // setActiveKeys(keys);
    setCompleted(data.filter(item => item.status == true).length);
  }, [checklist]);

  function getPanelAction(id) {
    switch (id) {
      case "verified":
        return <AppButton label="Verify" size="small" light onClick={() => modalStore.toggleModal(authStore?.user?.isEmailVerified ? "showUpgrade" : "verifyEmailOtp", true)} />;
      case "group":
        return (
          <AppButton
            label="Upload Contacts"
            size="small"
            light
            onClick={() => {
              history.push("/group");
              modalStore.toggleModal("showImportContactsModal", true);
            }}
          />
        );
      case "sender":
        return (
          <Formik
            initialValues={{
              sender: user ? user?.defaultSender : sharedNumber
            }}
            validationSchema={Yup.object().shape({
              sender: Yup.string().required("Select Sender ID")
            })}
            onSubmit={values => {
              let data = user;
              data.defaultSender = values.sender;
              updateProfileConfig(data).then(res => {
                if (res.defaultSender) {
                  setCollapseData(
                    collapseData.map(item => {
                      if (item.id == "sender") {
                        item.status = true;
                      }
                      return item;
                    })
                  );
                  setCompleted(completed + 1);
                }
              });
            }}
          >
            {({ setFieldValue, handleSubmit, values, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <SenderIDMenu setFieldValue={setFieldValue} values={values} error={errors.sender} touched={touched.sender} isMms={false} />
                <AppButton label="Confirm" size="small" className="me-2" light type="submit" />
              </Form>
            )}
          </Formik>
        );
      case "template":
        return (
          <AppButton
            label="Create Template"
            size="small"
            light
            onClick={() => {
              history.push("/template");
              modalStore.toggleModal("showCreateTemplateModal", true);
            }}
          />
        );
      case "campaign":
        return (
          <AppButton
            label="Run Campaign"
            size="small"
            light
            onClick={() => {
              history.push("/campaign/create");
              localStorage.removeItem("campaign");
              localStorage.setItem("step", 0);
              modalStore.toggleModal("showCreateCampaignModal", true);
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <Card className="rounded-xl shadow" bordered={false} loading={loading || !user}>
        <Row gutter={[10, 10]} type="flex" align="middle" justify="center">
          <Col span={12}>
            <Typography.Title level={4} className="text-info-color">
              Get Started Checklist
            </Typography.Title>
          </Col>
          <Col span={12} className="text-end">
            <Typography.Text>{completed} of 5 completed</Typography.Text>
          </Col>
          <Col span={24}>
            <Progress percent={(completed / 5) * 100} status="active" showInfo={false} />
          </Col>
          <Col span={24}>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              activeKey={activeKeys}
              onChange={keys => {
                setActiveKeys(keys);
                if (keys.includes("1")) history.push("/sender-ids");
              }}
            >
              {collapseData.map((item, index) => (
                <Panel
                  header={
                    <>
                      {item.status ? <CheckCircleOutlined className="text-success-color me-2" /> : <i className="far fa-circle text-muted me-2" />}
                      <Typography.Text strong>{item.title}</Typography.Text>
                    </>
                  }
                  key={index}
                  className="bg-white"
                >
                  <Typography.Paragraph>{item.status && item.success ? item.success : !item.status && item.pending ? item.pending : item.description}</Typography.Paragraph>
                  {!item.status && getPanelAction(item.id)}
                </Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
      </Card>
      <EmailOtpModal />
    </React.Fragment>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore
}))(observer(DashboardChecklist));
