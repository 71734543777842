import { Col, Row } from "antd";
import React from "react";
import View from "./View";

const UpgradeBanner = () => {
  const backGroundStyle = {
    minHeight: "550px",
    background: "rgb(166 199 227 / 20%)",
    borderRadius: "5px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)"
  };
  return (
    <React.Fragment>
      <Row type="flex" align="stretch" justify="center" className="my-5">
        <Col span={24} style={{ maxWidth: "500px" }} className="align-self-center ant-card rounded-xl shadow p-3">
          <View openModal={true} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpgradeBanner;
