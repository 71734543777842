import React, { useState } from "react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import View from "components/Template/View";
import TabsView from "components/MainCard/TabsView";

const Templates = props => {
  const { isVisible, handleCancel, onTemplateSelect, active, smallSize, zIndex = 1031, isMms } = props;
  const [tabKey, setTabKey] = useState("mms");

  return (
    <>
      <Modal
        destroyOnClose
        centered
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Select Your Template</h4>
            <p className={`mb-0 ${styles.modalDes}`}>
              Select template for your sales,
              <span>
                <br />
                Marketing, Customer Success and Business Communication
              </span>
            </p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={isVisible}
        onCancel={handleCancel}
        width={800}
        footer={null}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
        zIndex={zIndex}
      >
        {/* {isMms ? (
          <TabsView isMobile={smallSize} setTabKey={setTabKey} tabKey={tabKey}>
            <View
              onTemplateSelect={onTemplateSelect}
              active={active}
              smallSize={smallSize}
              isMms={tabKey === "mms"}
            />
          </TabsView>
        ) : ( */}
        <View onTemplateSelect={onTemplateSelect} active={active} smallSize={smallSize} isMms={isMms} />
        {/* )} */}
      </Modal>
    </>
  );
};

export default Templates;
