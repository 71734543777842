import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { Col, Pagination, Typography } from "antd";
import MobileCard from "Mobile/components/Card/Card";
import Header from "Mobile/components/Header/Header";
import AppLoader from "Mobile/components/Loader/Loader";

const GroupList = props => {
  const { groupStore, authStore } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { user } = authStore;

  useEffect(() => {
    if (
      user?.role == "subaccount" &&
      !user?.access?.includes("mms") &&
      user?.role == "subaccount" &&
      !user?.access?.includes("sms") &&
      user?.role == "subaccount" &&
      !user?.access?.includes("group")
    ) {
      history.push("/");
    } else {
      setLoading(true);
      groupStore.getAllGroupList().then(() => {
        setLoading(false);
      });
    }
  }, []);

  if (!groupStore.groupList) {
    return <AppLoader />;
  }

  const handleClick = item => {
    groupStore.selectGroup(item);
    history.push(`/group/${item._id}`);
  };

  const onChange = e => {
    groupStore.setPage(e);
    groupStore.getAllGroupList();
  };

  return (
    <div className="pb-3">
      <Header headerName="Group List" />
      <hr />
      {groupStore.groupList.length > 0 ? (
        <>
          {groupStore.groupList.map((item, i) => (
            <MobileCard
              onClick={() => {
                handleClick(item);
              }}
              selected={groupStore.selectedGroup === item._id}
              index={item._id}
              key={i}
              desc={
                <>
                  <Col span={24} className="d-flex flex-row">
                    <span className={`text-nowrap me-2`}>Group Name - </span>
                    <Typography.Text ellipsis className={`fw-bold`}>
                      {item?.name}
                    </Typography.Text>
                  </Col>
                  <Col span={24} className="d-flex flex-row">
                    <span className={`text-nowrap me-2`}>Category - </span>
                    <Typography.Text ellipsis className={`fw-bold`}>
                      {item.category ? item?.category.name : "No Category"}
                    </Typography.Text>
                  </Col>
                  <Col span={24} className="d-flex flex-row">
                    <span className={`text-nowrap me-2`}>Total Contacts - </span>
                    <Typography.Text ellipsis className={`fw-bold`}>
                      {item?.totalContacts > 0 ? item.totalContacts : "Empty Group"}
                    </Typography.Text>
                  </Col>
                </>
              }
            />
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <AppLoader />
          ) : (
            <div className="text-center">
              <p className="text-info-color mt-4">Please Create a Group in Web Portal</p>
            </div>
          )}
        </>
      )}
      {groupStore.totalPages <= 1 ? null : (
        <Pagination
          defaultCurrent={groupStore.current}
          total={groupStore.total}
          pageSize={groupStore.pageSize}
          onChange={onChange}
          className="text-start mt-2"
          simple={true}
          showQuickJumper
          hideOnSinglePage
        />
      )}
    </div>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  authStore: stores.store.authStore
}))(observer(GroupList));
