import { runInAction, decorate, observable } from "mobx";
import { getAllWorkflows, getWorkflowById, createWorkflow, updateAction, updateWorkflow, setActive, addAction, deleteAction, deleteWorkflow } from "service/workFlow";

class ObservableWorkFlowStore {
  workflowList = [];
  workflowName = undefined;
  selectedWorkflow = undefined;
  addWorkflowResponse = undefined;
  updateWorkflowResponse = undefined;
  deleteWorkflowResponse = undefined;
  selectedAction = undefined;
  addActionResponse = undefined;
  updateActionResponse = undefined;
  deleteActionResponse = undefined;
  pageSize = 10;
  current = 1;
  search = "";
  totalPages = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setWorkflowData = workflowData => {
    runInAction(() => {
      this.workflowName = workflowData.name;
    });
  };

  setResponse = (key, value) => {
    runInAction(() => {
      this[key] = value;
    });
  };

  getAllWorkflowsList = async () => {
    const params = {
      limit: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search) params.search = this.search;
    if (this.current) params.page = this.current;
    const workflowRespnse = await getAllWorkflows(params);
    try {
      this.workflowList = workflowRespnse.items;
      this.selectedWorkflow = undefined;
      this.current = workflowRespnse.current;
      this.pageSize = workflowRespnse.limit;
      this.total = workflowRespnse.total;
      this.totalPages = workflowRespnse.totalPages;
    } catch (error) {
      runInAction(() => {
        this.workflowList = error;
      });
    }
    return this.workflowList;
  };

  addWorkflow = async data => {
    const workflowRespnse = await createWorkflow(data);
    try {
      runInAction(() => {
        this.addWorkflowResponse = workflowRespnse;
      });
    } catch (error) {
      runInAction(() => {
        this.addWorkflowResponse = error;
      });
    }
    return this.addWorkflowResponse;
  };

  selectWorkflow = workflowData => {
    this.selectedWorkflow = workflowData ? JSON.parse(JSON.stringify(workflowData)) : undefined;
  };

  selectAction = actionData => {
    this.selectedAction = actionData ? JSON.parse(JSON.stringify(actionData)) : undefined;
  };

  updateWorkflow = async (id, data) => {
    const workflowRespnse = await updateWorkflow(id, data);
    try {
      runInAction(() => {
        this.updateWorkflowResponse = workflowRespnse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateWorkflowResponse = error;
      });
    }
    return this.updateWorkflowResponse;
  };

  setActive = async data => {
    const workflowRespnse = await setActive(data);
    try {
      runInAction(() => {
        this.updateWorkflowResponse = workflowRespnse;
        this.getAllWorkflowsList();
      });
    } catch (error) {
      runInAction(() => {
        this.updateWorkflowResponse = error;
      });
    }
    return this.updateWorkflowResponse;
  };

  getWorkflowById = async id => {
    const workflowRespnse = await getWorkflowById(id);
    try {
      runInAction(() => {
        this.selectedWorkflow = workflowRespnse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.selectedWorkflow = error;
      });
    }
    return this.selectedWorkflow;
  };

  addAction = async (id, data) => {
    const actionRespnse = await addAction(id, data);
    try {
      runInAction(() => {
        this.addActionResponse = actionRespnse;
      });
    } catch (error) {
      runInAction(() => {
        this.addActionResponse = error;
      });
    }
    return this.addActionResponse;
  };

  updateAction = async (id, data) => {
    const actionRespnse = await updateAction(id, data);
    try {
      runInAction(() => {
        this.updateActionResponse = actionRespnse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateActionResponse = error;
      });
    }
    return this.updateActionResponse;
  };

  deleteAction = async id => {
    const actionRespnse = await deleteAction(id);
    try {
      runInAction(() => {
        this.deleteActionResponse = actionRespnse;
      });
    } catch (error) {
      runInAction(() => {
        this.deleteActionResponse = error;
      });
    }
    return this.deleteActionResponse;
  };

  deleteWorkflow = async id => {
    const actionRespnse = await deleteWorkflow(id);
    try {
      runInAction(() => {
        this.deleteWorkflowResponse = actionRespnse;
        this.getAllWorkflowsList();
      });
    } catch (error) {
      runInAction(() => {
        this.deleteWorkflowResponse = error;
      });
    }
    return this.deleteWorkflowResponse;
  };
}

decorate(ObservableWorkFlowStore, {
  workflowList: observable,
  workflowName: observable,
  selectedWorkflow: observable,
  addWorkflowResponse: observable,
  updateWorkflowResponse: observable,
  deleteWorkflowResponse: observable,
  selectedAction: observable,
  deleteActionResponse: observable,
  addActionResponse: observable,
  updateActionResponse: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  totalPages: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable
});

export default new ObservableWorkFlowStore();
