import React from "react";
import { Card, Divider, Icon, Typography } from "antd";
import styles from "./card.module.css";

const { Paragraph } = Typography;

const MobileCard = props => {
  const { label, icon, desc, content, onClick, toggleBtn = false, selected, index, extra, ...rest } = props;
  const active = index === selected;
  return (
    <>
      {label && (
        <div className="d-flex justify-content-betweend-flex justify-content-between align-items-center">
          <Paragraph ellipsis className="pt-2 mb-0 fs-6 text-dark fw-bold">
            {label}
          </Paragraph>
        </div>
      )}
      <Card className={`shadow my-3 rounded ${styles.card} ${active ? styles.active : ""}`} bordered={false} {...rest}>
        <div onClick={onClick}>
          {label && icon && (
            <Paragraph ellipsis className={active ? "d-none" : styles.content + " fw-normal mb-3"}>
              {<Icon type={icon} className="me-3" />} {label}
            </Paragraph>
          )}
          {desc && <div className={active ? "d-none" : styles.content}>{desc}</div>}
          {content && <div className={!active ? "d-none" : styles.content}>{content}</div>}
        </div>
        {extra && active && (
          <>
            <Divider className="my-2" />
            {extra}
          </>
        )}
      </Card>
    </>
  );
};

export default MobileCard;
