import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Header from "Mobile/components/Header/Header";
import DashboardChecklist from "components/Dashboard/DashboardChecklist/Checklist";
import MobileCard from "Mobile/components/Card/Card";
import FloatButton from "Mobile/components/FloatButton/FloatButton";
import AppLoader from "Mobile/components/Loader/Loader";
import AlertModal from "components/Modals/AlertModal";
import { sharedNumber } from "constant/constant";
import QuickActions from "pages/Sms/Dashboard/QuickActions";

const Dashboard = props => {
  const { authStore, messageStore, gatewayBulkStore, dashboardGraphStore } = props;
  const { getChecklistStats, checklist } = dashboardGraphStore;
  const { user, allSenders, balanceObj } = authStore;
  const [selected, setSelected] = useState(0);
  const [draftAlert, setDraftAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // useEffect(() => {
  //   setLoading(true);
  //   getChecklistStats().finally(() => setLoading(false));
  // }, []);

  if (!balanceObj || !user) {
    return <AppLoader />;
  }

  const handleCreateCampaign = () => {
    if (localStorage.getItem("campaign")) {
      setDraftAlert(true);
    } else {
      history.push("/campaign/create");
      localStorage.setItem("step", 0);
      localStorage.removeItem("campaign");
      gatewayBulkStore.setCampaignData(null);
      messageStore.setCurrentMessage("");
      messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
      messageStore.setCurrentImage(null);
    }
  };

  return (
    <>
      <Header headerName="Dashboard" btnLabel="Start a Campaign" btn={authStore.user?.role !== "subaccount" || authStore.user?.access?.includes("sms")} onClick={handleCreateCampaign} />
      <QuickActions isMobile={true} />
      {/* <div className="my-4">
        <p className="mb-1">Report of your SMS Campaigns & Credits Balance</p>
      </div>
      <MobileCard
        index={2}
        label="Credits Balance"
        selected={selected}
        onClick={() => {
          if (selected === 2) {
            setSelected(0);
          } else {
            setSelected(2);
          }
        }}
        icon="credit-card"
        desc="Credits Balance available."
        content={
          <>
            <ul className="list-unstyled mb-0">
              <li className="d-flex flex-row justify-content-between">
                <h6 className="fw-bolder me-2" style={{ minWidth: "50px" }}>
                  {balanceObj?.maxPossibleCredits?.sms}
                </h6>
                <h6 className="m-0">SMS Credits</h6>
              </li>
              <li className="d-flex flex-row justify-content-between">
                <h6 className="fw-bolder me-2" style={{ minWidth: "50px" }}>
                  {balanceObj?.maxPossibleCredits?.mms}
                </h6>
                <h6 className="m-0">MMS Credits</h6>
              </li>
            </ul>
          </>
        }
      /> */}
      {/* {checklist && checklist.some(item => item.status == false) && (
        <DashboardChecklist checklist={checklist} loading={loading} />)}
      {authStore.user?.role !== "subaccount" ||
        (authStore.user?.access?.includes("sms") && <FloatButton />)}
      )} */}
      <FloatButton />
      <AlertModal
        visible={draftAlert}
        title="Use draft campaign"
        description="You have a pending campaign stored. Wanna use it?"
        primaryBtnProps={{
          label: "Use Draft",
          onClick: () => {
            history.push("/campaign/create");
            setDraftAlert(false);
          }
        }}
        secondaryBtn
        secondaryBtnProps={{
          label: "Create New",
          onClick: () => {
            setDraftAlert(false);
            localStorage.removeItem("campaign");
            gatewayBulkStore.setCampaignData(null);
            localStorage.setItem("step", 0);
            history.push("/campaign/create");
            messageStore.setCurrentMessage("");
            messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
            messageStore.setCurrentImage(null);
          }
        }}
        onCancel={() => setDraftAlert(false)}
        closable
        warning
      />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  messageStore: stores.store.messageStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  dashboardGraphStore: stores.store.dashboardGraphStore
}))(observer(Dashboard));
