import React from "react";
import styles from "./formPicker.module.scss";
import { TimePicker, Form } from "antd";

const AppTimePicker = props => {
  const {
    containerClassname = "",
    pickerClassname = "",
    containerProps = {},
    errors,
    touched,
    value,
    onChange,
    showTime,
    size = "large",
    name,
    placeholder = "Select Time",
    secondary = false,
    ...rest
  } = props;
  let finalClassNames = styles.formPickerPrimary;
  if (secondary) {
    finalClassNames = styles.formPickerSecondary;
  }
  return (
    <Form.Item className={`${finalClassNames} ${containerClassname}`} validateStatus={errors && touched ? "error" : ""} help={errors && touched ? errors : ""} {...containerProps}>
      <TimePicker showTime={showTime} placeholder={placeholder} onChange={onChange} name={name} value={value} size={size} className={pickerClassname} {...rest} />
    </Form.Item>
  );
};

export default AppTimePicker;
