import React from "react";
import { Modal } from "antd";
import errorIcon from "../../assets/images/modal/alert.svg";
import axios from "axios/index";
import _ from "lodash";
import { BASE_URL } from "constant/apiService";

import { cookie } from "utils/cookie/index";
import { AUTH_TOKEN, ROLE_TOKEN, REFRESH_TOKEN } from "constant/constant";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const authHeader = () => {
  const token = cookie.get(AUTH_TOKEN);
  const roletoken = sessionStorage.getItem(ROLE_TOKEN);
  return { Authorization: `Bearer ${token}`, roletoken };
};

const getHeaders = auth => {
  let headers = { ...defaultHeaders };
  if (auth) {
    headers = { ...headers, ...authHeader() };
  }
  return headers;
};

const url = (path, params) => {
  const sections = path.split(":");
  const sectionsWithParams = sections.map(section => (_.startsWith(section, "/") ? section : params[section]));
  const pathWithParams = sectionsWithParams.join("");
  return BASE_URL + pathWithParams;
};

const apiService = axios.create({});

// Function that will be called to refresh authorization
const refreshAuthLogic = async failedRequest => {
  return axios
    .post(`${BASE_URL}/auth/accessToken`, {
      accessToken: cookie.get(AUTH_TOKEN),
      refreshToken: cookie.get(REFRESH_TOKEN)
    })
    .then(({ data }) => {
      if (data.data.accessToken) {
        cookie.set(AUTH_TOKEN, data.data.accessToken);
        const newToken = `Bearer ${data.data.accessToken}`;
        failedRequest.response.config.headers["Authorization"] = newToken;
        //apiService.defaults.headers["Authorization"] = newToken;
        return Promise.resolve();
      } else {
        throw Error("Access token not found");
      }
    })
    .catch(error => {
      cookie.erase(AUTH_TOKEN);
      cookie.erase(REFRESH_TOKEN);
      window.location.href = "/login";
      return Promise.resolve();
    });
};

createAuthRefreshInterceptor(apiService, refreshAuthLogic, {
  statusCodes: [401]
});

export const get = (path, params = {}, auth = true, headers = {}) =>
  apiService.get(url(path, params), {
    params
  });

export const post = (path, params = {}, auth = true) => apiService.post(url(path, params), params);

export const put = (path, params = {}, auth = true) => apiService.put(url(path, params), params);

export const deleteRequest = (path, params = {}, auth = true) => apiService.delete(url(path, params), { params });

apiService.interceptors.request.use(request => {
  request.headers = getHeaders(true);
  return request;
});

apiService.interceptors.response.use(
  function(response) {
    if (_.has(response.data, "success") && !_.get(response.data, "success")) {
      function countDown() {
        let secondsToGo = 5;
        const modal = Modal.warning({
          title: <span className="fw-bold text-center fs-5">Error Occured</span>,
          content: <span className="text-center">{response.data.message}</span>,
          icon: (
            <img
              src={errorIcon}
              style={{
                margin: "0 auto 10px",
                width: "100%",
                height: "50px"
              }}
            />
          ),
          centered: true,
          zIndex: 1031,
          okButtonProps: {
            className: "px-4"
          }
        });
        setTimeout(() => {
          clearInterval();
          modal.destroy();
        }, secondsToGo * 1000);
      }
      countDown();
      // message.error(response.data.message);
      //GAEvent("error", "apiError", "fail", response.data.message, false);
      if (!response.data.message.startsWith("Bad")) {
        return Promise.reject(_.get(response.data, "message"));
      }
    } else if (_.has(response.data, "status") && !_.get(response.data, "status")) {
      function countDown() {
        let secondsToGo = 5;
        const modal = Modal.warning({
          title: <span className="fw-bold text-center fs-5">Error Occured</span>,
          content: <span className="text-center">{response.data.message}</span>,
          icon: (
            <img
              src={errorIcon}
              style={{
                margin: "0 auto 10px",
                width: "100%",
                height: "50px"
              }}
            />
          ),
          centered: true,
          zIndex: 1031,
          okButtonProps: {
            className: "px-4"
          }
        });
        setTimeout(() => {
          clearInterval();
          modal.destroy();
        }, secondsToGo * 1000);
      }
      countDown();
      // message.error(response.data.message, 8);
    }
    return response;
  },
  function(error) {
    if (error.response && error.response.data && error.response.data.message) {
      //GAEvent("error", "apiError", "fail", error.response.data.message, false);
      if (!error.response.data.message.startsWith("Bad")) {
        function countDown() {
          let secondsToGo = 5;
          const modal = Modal.warning({
            title: <span className="fw-bold text-center fs-5">Error Occured</span>,
            content: <span className="text-center">{error.response.data.message}</span>,
            icon: (
              <img
                src={errorIcon}
                style={{
                  margin: "0 auto 10px",
                  width: "100%",
                  height: "50px"
                }}
              />
            ),
            centered: true,
            zIndex: 1031,
            okButtonProps: {
              className: "px-4"
            }
          });
          setTimeout(() => {
            clearInterval();
            modal.destroy();
          }, secondsToGo * 1000);
        }
        countDown();
        // message.error(error.response.data.message);
        if (error.response.data.message === "Email already exists, Please login") {
          window.location.href = "/";
        }
      }
    }
    return Promise.reject(error.response);
  }
);
