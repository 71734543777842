import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Row, Col, Form, message as messageToast, Typography, List } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import { STRIPE_KEY } from "constant/apiService";
import OrderSummary from "./OrderSummary";
import CardSelector from "./CardSelector";

const stripePromise = loadStripe(STRIPE_KEY);

const PaymentForm = ({
  totalPrice,
  userStore,
  totalSMS,
  bonus,
  withOutTax,
  costperSMS,
  modalStore,
  special = false,
  specialPlanId,
  isFirstBuy = false,
  isBundle = false,
  dedicatedNumStore,
  isDedicatedPurchase = false
}) => {
  return (
    <Row gutter={[10, 10]} className="align-items-stretch" type="flex">
      <Col
        md={{
          span: 12,
          order: 1
        }}
        span={24}
        order={2}
        className="h-auto"
      >
        <Elements stripe={stripePromise}>
          <CheckoutForm
            modalStore={modalStore}
            userStore={userStore}
            totalSms={totalSMS}
            withOutTax={withOutTax}
            totalPrice={totalPrice}
            isBundle={isBundle}
            special={special}
            specialPlanId={specialPlanId}
            dedicatedNumStore={dedicatedNumStore}
            isDedicatedPurchase={isDedicatedPurchase}
          />
        </Elements>
      </Col>
      <Col
        md={{
          span: 12,
          order: 2
        }}
        span={24}
        order={1}
        className="h-auto"
      >
        <OrderSummary quantity={totalSMS} cost={costperSMS} price={withOutTax} isFirstBuy={isFirstBuy} total={totalPrice} bonus={bonus} />
      </Col>
    </Row>
  );
};

export default inject(stores => ({
  userStore: stores.store.userStore,
  modalStore: stores.store.modalStore,
  dedicatedNumStore: stores.store.dedicatedNumStore
}))(observer(PaymentForm));

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#149cbe",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fff"
      },
      "::placeholder": {
        color: "#afafaf"
      }
    },
    invalid: {
      iconColor: "#ff001a"
    }
  }
};

const CardField = ({ onChange }) => <CardElement options={CARD_OPTIONS} className="border px-3 rounded padding-y8" onChange={onChange} />;

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { totalPrice, totalSms, modalStore, withOutTax, userStore, special, specialPlanId, dedicatedNumStore, isDedicatedPurchase, isBundle } = props;
  const { customPayment, subscribeToPlan, buyProductBundle, buyCreditPackage } = userStore;
  const { purchaseDedicatedNum } = dedicatedNumStore;

  const handleSubmit = async values => {
    let paymentMethod = isDedicatedPurchase ? purchaseDedicatedNum : isBundle ? buyProductBundle : special ? subscribeToPlan : customPayment;

    if (special) {
      values.credit = totalSms;
      values.specialPlanId = specialPlanId;
    } else if (isBundle) {
      values.productId = buyCreditPackage.id;
    } else if (isDedicatedPurchase) {
      values.amount = totalPrice;
      values.dedicated_id = dedicatedNumStore.selectedDedicatedNum?._id;
    } else {
      values.amount = totalPrice;
      if (modalStore.isMms) {
        values.mmsCredit = totalSms;
        values.smsCredit = ((totalPrice * 100) / 5.5).toFixed(0);
        values.type = "mms";
      } else {
        values.smsCredit = totalSms;
        values.mmsCredit = ((totalPrice * 100) / 39).toFixed(0);
        values.type = "sms";
      }
      values.amountWithOutTax = withOutTax;
    }
    setLoading(true);
    if (values.card === "NewCard") {
      values.card = undefined;
      stripe.createToken(elements.getElement(CardElement)).then(result => {
        const { token, error } = result;
        if (error) {
          setLoading(false);
          messageToast.error(error.message);
        } else {
          if (special) {
            values.token = token.id;
          } else {
            values.source = token.id;
            values.dedicated_id = dedicatedNumStore.selectedDedicatedNum?._id;
          }
          paymentMethod(values)
            .then(statusM => {
              const status = toJS(statusM);
              if (status.statusCode === 400 || status.statusCode === 401 || status.statusCode === 402 || status.statusCode === 403 || !status.status) {
                if (status?.raw?.message) messageToast.error(`${status.raw.message}`);
                setLoading(false);
                modalStore.toggleModal("showPaymentModal", false);
              } else {
                messageToast.success(status.message);
                setLoading(false);
                history.push(isDedicatedPurchase ? "/" : location?.state?.from);
                modalStore.toggleModal("showPaymentModal", false);
              }
            })
            .finally(() => setLoading(false));
        }
      });
    } else {
      paymentMethod(values)
        .then(statusM => {
          const status = toJS(statusM);
          if (status.statusCode === 400 || status.statusCode === 401 || status.statusCode === 402 || status.statusCode === 403 || !status.status) {
            if (status?.raw?.message) messageToast.error(`${status.raw.message}`);
            setLoading(false);
            modalStore.toggleModal("showPaymentModal", false);
          } else {
            messageToast.success(status.message);
            setLoading(false);
            history.push(isDedicatedPurchase ? "/" : location?.state?.from);
            modalStore.toggleModal("showPaymentModal", false);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        city: "",
        card: "NewCard",
        isNewCard: false
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .when("card", {
            is: "NewCard",
            then: Yup.string()
              .min(3, "Please Enter a Valid Name")
              .required("Please Enter Card Holder Name")
          })
          .nullable(),
        card: Yup.string().required("Please Choose a payment option"),
        city: Yup.string()
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className="rounded-xl border p-md-4 p-2 h-100">
            <Row type="flex" align="start" className="h-100">
              <Col span={24}>
                <Typography.Title className="text-uppercase gotham-bold" level={4}>
                  Payment Method
                </Typography.Title>
              </Col>
              <CardSelector setFieldValue={setFieldValue} values={values} />
              {values?.isNewCard ? (
                <>
                  <Col span={24}>
                    <Typography.Title className="fs-5" level={4}>
                      Add New Card
                    </Typography.Title>
                  </Col>
                  <Col span={24}>
                    <FormInput
                      type="text"
                      name="name"
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      onChange={handleChange}
                      placeholder="Card Holder Name"
                      inputProps={{ autoComplete: "off" }}
                      inputClassname="border-muted-color p-3"
                    />
                  </Col>
                  <Col span={24}>
                    <Form.Item validateStatus={errors.card && touched.card ? "error" : ""} help={errors.card && touched.card ? errors.card : ""}>
                      <CardField className="border-info-color" onChange={e => setFieldValue("card", "NewCard")} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <FormInput
                      inputClassname="border-muted-color p-3"
                      placeholder="City"
                      type="text"
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                      error={errors.city}
                      touched={touched.city}
                    />
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <List
                    style={{ margin: "0 auto" }}
                    itemLayout="horizontal"
                    dataSource={[{ id: "new" }]}
                    renderItem={item => (
                      <List.Item
                        onClick={() => {
                          setFieldValue("isNewCard", true);
                          setFieldValue("card", "NewCard");
                        }}
                        style={{ cursor: "pointer" }}
                        className={`rounded-3 mb-3 px-2 bg-white border-muted-color border-bottom`}
                      >
                        <List.Item.Meta
                          className="align-items-center mb-0 mt-2"
                          title={
                            <Typography.Text strong className="fs-5 ms-4 mb-0">
                              <i class="bx bx-credit-card align-middle fs-2 me-3" />
                              Add New Card
                            </Typography.Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              )}
              <Col span={24} className="text-center">
                <AppButton type="submit" className={`rounded-pill w-50 shadow-sm ${loading ? "bg-secondary text-white" : ""}`} label={loading ? <LoadingOutlined /> : "Pay"} disabled={loading} />
              </Col>
              {loading && (
                <Col span={24} className="text-center">
                  <Typography.Text type="secondary">Please wait while we process your payment</Typography.Text>
                </Col>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
