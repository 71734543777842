import { decorate, observable, runInAction } from "mobx";
import _ from "lodash";
import * as mobx from "mobx";
import moment from "moment";

class ObservableModalStore {
  //bool
  isMms = localStorage.getItem("isMms") === "true" ? true : false;
  showQuickSMSModal = false;
  showAttachFileModal = false;
  showScheduleMessageModal = false;
  showShortUrlModal = false;
  showTestSMSModal = false;
  showFollowUpModal = false;
  showExportHistoryModal = false;
  showAddMfaEmailModal = false;

  showAddOptOutContactModal = false;
  showSingleOptOutContactModal = false;
  showOptOutTemplateModal = false;
  showImportOptOutContactsModal = false;

  showCreateCategoryModal = false;
  showRenameCategoryModal = false;

  showInfoModal = false;
  showCreateEmailModal = false;
  showMessageSettingModal = false;
  showManageEmailModal = false;

  showUnicodeNotificationModal = false;
  showUnicodeRemoveModal = false;

  showPaymentModal = false;

  showProviderRouteModal = false;
  showProviderSQSModal = false;
  showSetDefaultSenderModal = false;

  // RoleAccess, inviteModal
  showInviteModal = false;
  showDataDrawer = false;

  // Template Vars
  showCreateTemplateModal = false;
  showChooseTemplateModal = false;
  selectedTemplate = null;

  // group vars
  showCreateGroupModal = false;
  showMoveContact = false;
  showImportContactsModal = false;
  showAddContactsModal = false;
  showChooseGroupModal = false;
  selectedGroup = null;
  showQuickContactModal = false;
  showAddMergeLabelModal = false;
  showMergeLabelModal = false;

  // workflow vars
  showCreateWorkflowModal = false;
  showAddActionModal = false;

  // mms editor
  showImageCropModal = false;
  showVideoTrimmerModal = false;

  // app settings
  enableSettings = false;
  quickMessage = false;

  // square space
  retailChatInvoice = false;

  //data
  longUrl = null;
  scheduleDateTime = moment();
  quickSMSContact = undefined;
  quickSMSContent = undefined;
  quickSMSSender = undefined;
  selectedContacts = null;
  subject = null;
  // to reset quick sms form
  handleReset = undefined;

  //trial user
  showUpgrade = false;
  verifyEmailOtp = false;

  // dedicated number
  purchaseDedicatedNo = false;
  addDedicatedNoModal = false;
  addSingleDedicateNoModal = false;
  addBulkDedicatedNumModal = false;

  // user AdditionalDetails
  userFormPreview = false;
  rejectionReasonModal = false;

  // export notification
  showExportNotification = false;
  exportMessage = "We'll email the following data when your export has processed";
  exportEmail = null;

  importContactsModal = false;
  createCampaignModal = false;

  campaignCreditPurchaseModal = false;

  showUnicodeModal = false;

  setReset = callback => {
    runInAction(() => {
      this.handleReset = callback;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  toggleModal(modalName, state) {
    runInAction(() => {
      this[modalName] = state;
    });
  }

  setData(key, data) {
    runInAction(() => {
      this[key] = data;
    });
  }

  setQuickSMSData(data) {
    runInAction(() => {
      if (data) {
        if (data.receiver) this.quickSMSContact = data.receiver;
        if (data.message) this.quickSMSContent = data.message;
        if (data.sender) this.quickSMSSender = data.sender;
        if (data.longUrl) this.longUrl = data.longUrl;
        if (data.subject) this.subject = data.subject;
      } else {
        this.quickSMSContact = undefined;
        this.quickSMSContent = undefined;
        this.quickSMSSender = undefined;
        this.longUrl = undefined;
        this.subject = undefined;
      }
    });
  }

  setContacts(contacts) {
    runInAction(() => {
      this.quickSMSContact = contacts;
    });
  }

  setIsMms = isMms => {
    runInAction(() => {
      localStorage.setItem("isMms", isMms);
      this.isMms = isMms;
    });
  };
}

decorate(ObservableModalStore, {
  isMms: observable,
  showQuickSMSModal: observable,
  showAttachFileModal: observable,
  showScheduleMessageModal: observable,
  showShortUrlModal: observable,
  showTestSMSModal: observable,
  showFollowUpModal: observable,
  showExportHistoryModal: observable,
  showAddOptOutContactModal: observable,
  showSingleOptOutContactModal: observable,
  showOptOutTemplateModal: observable,
  showImportOptOutContactsModal: observable,
  showCreateCategoryModal: observable,
  showRenameCategoryModal: observable,
  showInfoModal: observable,
  showCreateEmailModal: observable,
  showMessageSettingModal: observable,
  showManageEmailModal: observable,
  showUnicodeNotificationModal: observable,
  showUnicodeRemoveModal: observable,
  showPaymentModal: observable,
  showSetDefaultSenderModal: observable,
  showAddMfaEmailModal: observable,

  // RoleAccess, inviteModal
  showInviteModal: observable,
  showDataDrawer: observable,

  // Template Vars
  showCreateTemplateModal: observable,
  showChooseTemplateModal: observable,
  selectedTemplate: observable,

  // Group Vars
  showAddContactsModal: observable,
  showCreateGroupModal: observable,
  showMoveContact: observable,
  showImportContactsModal: observable,
  showChooseGroupModal: observable,
  selectedGroup: observable,
  showQuickContactModal: observable,
  showAddMergeLabelModal: observable,
  showMergeLabelModal: observable,

  // Workflow Vars
  showCreateWorkflowModal: observable,
  showAddActionModal: observable,

  longUrl: observable,
  selectedContacts: observable,
  quickSMSContact: observable,
  quickSMSContent: observable,
  quickSMSSender: observable,
  subject: observable,
  handleReset: observable,

  showEditCampaignModal: observable,
  showSelectLabelModal: observable,
  showCreateLabelModal: observable,

  // trial user
  showUpgrade: observable,
  verifyEmailOtp: observable,

  // dedicated no
  purchaseDedicatedNo: observable,
  addDedicatedNoModal: observable,
  addSingleDedicateNoModal: observable,
  addBulkDedicatedNumModal: observable,

  // user AdditionalDetails
  userFormPreview: observable,
  rejectionReasonModal: observable,

  // mms editor
  showVideoTrimmerModal: observable,
  showImageCropModal: observable,

  // app settings
  enableSettings: observable,
  quickMessage: observable,

  // export notification
  showExportNotification: observable,
  exportMessage: observable,
  exportEmail: observable,

  retailChatInvoice: observable,

  showProviderRouteModal: observable,
  showProviderSQSModal: observable,

  importContactsModal: observable,
  createCampaignModal: observable,

  campaignCreditPurchaseModal: observable,
  showUnicodeModal: observable
});
export default new ObservableModalStore();
