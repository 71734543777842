import { get, post, put, deleteRequest } from "utils/api";

/**
 * Call get all file schedules api
 * @returns {Promise<any>}
 */
export const getAllFileSchedulers = async params => {
  const fileScheduleListResponse = await get("/scheduler/file", params);
  return fileScheduleListResponse.data;
};

/**
 * Add new file scheduler api
 * @param fileSchedulerData
 * @returns {Promise<any>}
 */
export const addFileScheduler = async fileSchedulerData => {
  const addFileSchedulerResponse = await post(`/scheduler/file`, fileSchedulerData);
  return addFileSchedulerResponse.data;
};

/**
 * Update existing scheduler Api
 * @param schedulerData
 * @param schedulerId
 * @returns {Promise<any>}
 */
export const updateFileScheduler = async (fileSchedulerData = {}, fileSchedulerId) => {
  const updateFileSchedulerResponse = await put(`/scheduler/file/${fileSchedulerId}`, fileSchedulerData);
  return updateFileSchedulerResponse.data;
};

/**
 * Delete scheduler
 * @param SchedulerId
 * @returns {Promise<any>}
 */
export const deleteFileScheduler = async fileSchedulerId => {
  const deleteFileSchedulerResponse = await deleteRequest(`/scheduler/file/${fileSchedulerId}`);
  return deleteFileSchedulerResponse.data;
};
