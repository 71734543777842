import { runInAction, decorate, observable } from "mobx";
import { getAllBlocks, addBlock, updateBlock, deleteBlock } from "service/blockService";

class ObservableBlockStore {
  blockList = [];
  /**
   * get all Blocks
   * @returns {Promise<[]>}
   */
  getAllBlockList = async params => {
    const blockListResponse = await getAllBlocks(params);
    try {
      runInAction(() => {
        this.blockList = blockListResponse ? [blockListResponse] : [];
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.blockList;
  };

  /**
   * add new Block
   * @param blockData
   * @returns {Promise<*>}
   */
  addBlock = async blockData => {
    // call api for add new Block
    const addBlockResponse = await addBlock(blockData);
    try {
      runInAction(() => {
        this.blockList = [addBlockResponse];
      });
    } catch (error) {
      runInAction(() => {
        this.addBlockResponse = error;
      });
    }
    return this.addBlockResponse;
  };

  updateBlock = async blockData => {
    // call api for add new Block
    const updateBlockResponse = await updateBlock(blockData, blockData._id);
    try {
      runInAction(() => {
        this.blockList = [updateBlockResponse];
      });
    } catch (error) {
      runInAction(() => {
        this.addBlockResponse = error;
      });
    }
    return this.addBlockResponse;
  };

  /**
   * select Block Data for Update
   * @param blockData
   */
  selectBlock = blockData => {
    this.selectedBlock = blockData ? JSON.parse(JSON.stringify(blockData)) : undefined;
  };
}

decorate(ObservableBlockStore, {
  blockList: observable
});
export default new ObservableBlockStore();
