import { get, post, put, deleteRequest } from "utils/api";

/**
 * Call get all scheduler api
 * @returns {Promise<any>}
 */
export const getAllSchedulers = async params => {
  // save api response for Scheduler List list
  const SchedulerListResponse = await get("/scheduler/group", params);
  return SchedulerListResponse.data;
};

/**
 * Add new scheduler api
 * @param schedulerData
 * @returns {Promise<any>}
 */
export const addScheduler = async (schedulerData = {}) => {
  const addResponse = await post(`/scheduler/group`, schedulerData);
  return addResponse.data;
};

/**
 * Update existing scheduler Api
 * @param schedulerData
 * @param schedulerId
 * @returns {Promise<any>}
 */
export const updateScheduler = async (schedulerData = {}, schedulerId) => {
  const updateResponse = await put(`/scheduler/group/${schedulerId}`, schedulerData);
  return updateResponse.data;
};

/**
 * Delete scheduler
 * @param SchedulerId
 * @returns {Promise<any>}
 */
export const deleteScheduler = async schedulerId => {
  const deleteResponse = await deleteRequest(`/scheduler/group/${schedulerId}`);
  return deleteResponse.data;
};

export const updateSchedulerStatus = async schedulerId => {
  const updateSchedulerResponse = await put(`/scheduler/pause/${schedulerId}`);
  return updateSchedulerResponse.data;
};

export const getAllSchedulerLogList = async schedulerId => {
  const schedulerLogListResponse = await get(`/scheduler/log/${schedulerId}`);
  return schedulerLogListResponse.data;
};

export const getAllSchedulerHistoryList = async schedulerId => {
  const schedulerHistoryResponse = await get(`/scheduler/history/${schedulerId}`);
  return schedulerHistoryResponse.data;
};
