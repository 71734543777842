import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Col, Row, Typography, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import PhoneFrame from "components/PhoneFrame/PhoneFrame";

const QuickSmsWrapper = ({ children, modalStore, messageStore }) => {
  if (!modalStore.quickMessage) {
    return null;
  }

  const history = useHistory();
  const location = useLocation();

  return (
    <Col span={24} lg={10} xl={7}>
      <Card className={`rounded-xl my-3 shadow`} bordered={false} style={{ height: "fit-content" }}>
        <Row gutter={[10, 20]} type="flex" align="middle" justify="center">
          <Col span={12}>
            <Typography.Title level={4} className="text-info-color mb-0 text-nowrap">
              Quick Message
            </Typography.Title>
          </Col>
          <Col span={12} className="justify-content-end d-flex gap-3">
            <Tooltip title="Expand">
              <i
                className="fas fs-6 fa-expand-alt text-info-color"
                type="button"
                onClick={() => {
                  localStorage.setItem("lastLocation", location.pathname);
                  modalStore.toggleModal("quickMessage", false);
                  history.push("/quick-message");
                }}
              />
            </Tooltip>
            <Tooltip title="Close">
              <i
                className="fas fs-6 fa-times text-info-color"
                type="button"
                onClick={() => {
                  modalStore.toggleModal("quickMessage", false);
                  modalStore.setQuickSMSData(null);
                }}
              />
            </Tooltip>
          </Col>
          <Col>
            <PhoneFrame
              smallFrame
              headerName={messageStore.phoneFrameSender}
              currentMessageContent={messageStore.phoneFrameCurrentMessage}
              phoneFrameImageUrl={messageStore.phoneFrameImageUrl}
              isMms={modalStore.isMms}
            />
          </Col>
          <Col span={24}>{children}</Col>
        </Row>
      </Card>
    </Col>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore
}))(observer(QuickSmsWrapper));
