import React, { useEffect } from "react";
import { Form } from "antd";
import formStyles from "components/FormItems/formItems.module.scss";
import TextareaAutosize from "react-textarea-autosize";

function MessageBox(props) {
  const {
    label,
    placeholder = "Type a message",
    name,
    value,
    onChange,
    error,
    touched,
    disabled,
    secondary = false,
    minRows = 8,
    maxRows = 6,
    containerClassname = "",
    textAreaClassname = "",
    containerProps = {},
    textAreaProps = {},
    colon = false,
    textAreaRef,
    style
  } = props;

  let finalClassNames = formStyles.formInputPrimary;
  if (secondary) {
    finalClassNames = formStyles.formInputSecondary;
  }

  function scrollToView() {
    if (textAreaProps.ref) {
      textAreaProps.ref.current.scrollTop = textAreaProps?.ref.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollToView();
  }, [value]);

  return (
    <Form.Item label={label} validateStatus={error && touched ? "error" : ""} help={touched ? error : ""} className={`${finalClassNames} ${containerClassname}`} colon={colon} {...containerProps}>
      <TextareaAutosize
        name={name}
        placeholder={placeholder}
        value={value ? value : ""}
        onChange={onChange}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        className={`${textAreaClassname} py-0 border-0`}
        {...textAreaProps}
        ref={textAreaRef}
        style={{ lineHeight: "25px", resize: "none", ...style }}
      ></TextareaAutosize>
    </Form.Item>
  );
}

export default MessageBox;
