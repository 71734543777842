import { get, post, put, deleteRequest } from "utils/api";

// Add Business Name (user)
export const addBusinessName = async values => {
  const addBusinessName = await put("/user/addbuisness", values);
  return addBusinessName.data;
};

// Delete Business Name (user)
export const deleteBusinessName = async values => {
  const deleteBusinessName = await put("/user/deletebuisness", values);
  return deleteBusinessName.data;
};

// Get All Blocked Business Names (admin)
export const getBlockBusinessNames = async params => {
  const getBlockBusinessNames = await get("/admin/blockedWord", params);
  return getBlockBusinessNames.data.data;
};

// Block A Business Name (admin)
export const blockBusinessName = async values => {
  const blockBusinessName = await post("/admin/blockedWord", values);
  return blockBusinessName.data;
};

// Unblock A Business Name (admin)
export const deleteBlockBusinessName = async id => {
  const deleteBlockBusinessName = await deleteRequest(`/admin/blockedWord/${id}`);
  return deleteBlockBusinessName.data;
};

// Get All Business Names for Verification (admin)
export const getBusinessNamesVerificationList = async params => {
  const response = await get(`/admin/buisness-name-verification-list`, params);
  return response.data.data;
};

// approve or reject business name (admin)
export const approveOrRejectBusinessName = async (id, values) => {
  const response = await post(`/admin/approve-reject-buisness-name/${id}`, values);
  return response.data;
};

// add business name for verification (user)
export const addBusinessNameForVerification = async values => {
  const response = await post(`/buisness`, values);
  return response.data;
};
// get all business names with status of approval (user)
export const getListBusinessNames = async values => {
  const response = await get(`/buisness`, values);
  return response.data.data;
};

// delete business name (admin)
export const deleteBusinessNameAdmin = async id => {
  const response = await deleteRequest(`/admin/delete-business-admin/${id}`);
  return response.data;
};
