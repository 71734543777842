import React from "react";
import { Row, Col, Tooltip } from "antd";
import AppDatePicker from "components/AppPickers/AppDatePicker";
import FormSelectInput from "components/FormItems/FormSelectInput";
import AppButton from "components/AppButton/AppButton";
import { messageStatus, messageTypes } from "constant/commonList";
import FormInput from "components/FormItems/FormInput";

const FilterBar = props => {
  const {
    fullSize = false,
    dates,
    changeDates,
    status,
    changeStatus,
    type = false,
    messageType,
    changeMessageType,
    exportBtn = false,
    handleExport,
    showSearch = false,
    contact,
    searchContact,
    refreshBtn = false,
    onRefresh,
    btnProps
  } = props;

  return (
    <Row gutter={[10, 10]} type="flex" align="middle">
      {showSearch && (
        <Col lg={7} md={12} span={24}>
          <FormInput size="default" containerClassname="mb-0" name="contact" placeholder="Search Contact" value={contact} onChange={searchContact} />
        </Col>
      )}
      <Col lg={7} md={12} span={24}>
        <AppDatePicker value={dates} onChange={changeDates} containerClassname="mb-0" pickerClassname="w-100" size="default" rangeSelector name="rangepicker" />
      </Col>
      <Col lg={5} md={12} span={12}>
        <FormSelectInput
          size="default"
          containerClassname="mb-0"
          selectEleClassnames="w-100"
          name="status"
          placeholder="Select Status"
          options={messageStatus}
          onChange={changeStatus}
          allowClear
          value={status ? status : undefined}
        />
      </Col>
      {exportBtn && (
        <Col lg={4} md={6} span={10}>
          <AppButton label={"Export History"} onClick={handleExport} light className={"w-100"} {...btnProps} />
        </Col>
      )}
      {refreshBtn && (
        <Col lg={1} span={2} className="text-center">
          <Tooltip title="Refresh">
            <i className="fas fa-sync-alt text-info-color fs-5" type="button" onClick={onRefresh} />
          </Tooltip>
        </Col>
      )}
      {type && (
        <Col xl={5} lg={5} md={12} span={10}>
          <FormSelectInput size="default" containerClassname="mb-0" selectEleClassnames="w-100" placeholder="Select Type" value={messageType} options={messageTypes} onChange={changeMessageType} />
        </Col>
      )}
    </Row>
  );
};

export default FilterBar;
