import React from "react";
import { Table } from "antd";
import styles from "./table.module.css";

const AppTable = ({ rowSelection, columns, dataSource, scroll, pagination, footer, secondary = false, className, loading, ...rest }) => {
  return (
    <Table
      rowSelection={rowSelection}
      className={`${className} ${styles.table}`}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      scroll={scroll}
      footer={footer}
      loading={loading}
      {...rest}
    />
  );
};

export default AppTable;
